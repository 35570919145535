
import { plainToClass } from 'class-transformer';
import { Component, Mixins } from 'vue-property-decorator';
import { validate } from 'class-validator';
import { $enum } from 'ts-enum-util';
import { inject } from '@/inversify';

import EventsFilterService, { EventsFilterServiceS } from '@/modules/events/events-filter.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

import DownloadReportForm, { DownloadReportControlMixin } from '@/modules/common/components/download-report-form.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomDateRangePicker from '@/modules/common/components/ui-kit/custom-date-range-picker.vue';
import EVENT_TYPE_SETTINGS from '../constants/event-types-settings.constant';
import EventsExcelFormModel from '../models/events-excel-form.model';

const DAY_RANGE_PRESETS = [30, 60, 90];

@Component({
    components: {
        DownloadReportForm,
    },
})
export default class EventsDownloadExcel extends Mixins(DownloadReportControlMixin) {
    @inject(EventsManagerServiceS)
    private eventsManagerService!: EventsManagerService;

    @inject(EventsFilterServiceS)
    private eventsFiltersService!: EventsFilterService;

    @inject(UserServiceS)
    private userService!: UserService;

    private isLoading = false;
    private isDownloadDisabled = false;
    private form_: Record<string, any> = {};

    get form() {
        return this.form_;
    }

    set form(value) {
        this.form_ = value;
        this.$nextTick(() => this.validateForm(this.form_));
    }

    get attrs() {
        const { filters, buttons, isLoading } = this;
        const { properties } = this;

        return {
            filters,
            buttons,
            isLoading,
            properties,
            dataType: this.$tc('titles.events'),
        };
    }

    private get properties() {
        return [
            {
                label: this.$tc('rates.datesCount'),
                key: 'daysCount',
                default: -1,
                component: CustomSelect,
                props: {
                    items: DAY_RANGE_PRESETS
                        .map(days => ({
                            name: this.$t('insights.nextDays', [days]).toString(),
                            value: days,
                        }))
                        .concat([{
                            name: this.$tc('rates.customRange'),
                            value: -1,
                        }]),
                },
                handlers: {
                    input: this.onDaysCountChange,
                },
            },
            {
                label: this.$tc('dateRange'),
                key: 'monthrange',
                component: CustomDateRangePicker,
                default: this.getDateRangeByDays(DAY_RANGE_PRESETS[0]),
                props: {
                    maxDays: 365,
                },
                handlers: {
                    input: this.onDateRangeChange,
                },
            },
        ];
    }

    private onDaysCountChange(value: any) {
        this.form.monthrange = this.getDateRangeByDays(value);
    }

    private getDateRangeByDays(daysCount: number) {
        if (daysCount === -1) {
            return [];
        }

        const startDate = new Date(new Date().toISOString().split('T')[0]);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + daysCount);

        return [startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]];
    }

    private onDateRangeChange(range: [string, string]) {
        const today = new Date();
        const start = new Date(range[0]);

        const isStartToday = start.getDate() === today.getDate()
            && start.getMonth() === today.getMonth()
            && start.getFullYear() === today.getFullYear();

        if (!isStartToday) {
            this.form.daysCount = -1;
            return;
        }

        const end = new Date(range[1]);
        const deltaDays = Math.ceil(Math.abs(start.getTime() - end.getTime()) / (1000 * 3600 * 24)) + 1;

        if (DAY_RANGE_PRESETS.includes(deltaDays)) {
            this.form.daysCount = deltaDays;
        } else {
            this.form.daysCount = -1;
        }
    }

    private get isHotelLevel() {
        return this.userService.isViewAsHotel;
    }

    private get buttons() {
        return [
            {
                label: this.$tc('popup.download'),
                onClick: () => this.downloadExcel(),
                isDisabled: this.isDownloadDisabled,
            },
        ];
    }

    private get filters() {
        const { allCountries, allCountryCodes } = this.eventsFiltersService;
        const { countries } = this.eventsFiltersService.settings;

        const defaultCountries = countries
            .map(name => allCountryCodes[allCountries.indexOf(name)]);

        const countryOptions = allCountries
            .map((name, i) => ({
                name,
                value: allCountryCodes[i],
            }))
            .sort((a, b) => {
                const ccA = countries.includes(a.name);
                const ccB = countries.includes(b.name);

                if (ccA !== ccB) return ccA ? -1 : 1;

                return 0;
            });

        const defaultLevels = this.isHotelLevel
            ? ['cluster', 'hotel', 'market']
            : ['cluster', 'market'];

        return [
            {
                label: this.$tc('events.popup.eventType'),
                options: $enum(EVENT_TYPE_SETTINGS).getKeys().map(key => ({
                    name: this.$tc(['filters.events', key.toLowerCase()].join('.')),
                    value: EVENT_TYPE_SETTINGS[key],
                })),
                default: this.eventsFiltersService.settings.types,
                key: 'type',
                multiselect: true,
                valuesOnly: true,
            },
            {
                label: this.$tc('events.popup.level'),
                options: defaultLevels.map(key => ({
                    name: this.$tc(`titles.${key}`),
                    value: key,
                })),
                default: defaultLevels,
                key: 'entity_type',
                multiselect: true,
                valuesOnly: true,
            },
            {
                label: this.$tc('titles.holiday'),
                key: 'country_codes',
                options: countryOptions,
                default: defaultCountries,
                multiselect: true,
                valuesOnly: true,
            },
        ].filter(Boolean);
    }

    private async validateForm(form: any) {
        const formToSend = plainToClass(EventsExcelFormModel, form, { excludeExtraneousValues: true });

        const [error] = await validate(formToSend);
        const { form: formComponent } = this.$refs as { form: DownloadReportForm };

        if (error) {
            const translationKey = `events.excelFormValidation.${error.property}.${Object.keys(error.constraints!)[0]}`;
            const message = this.$tc(translationKey);
            formComponent?.error(message);
        }

        this.isDownloadDisabled = !!error;
    }

    private async downloadExcel() {
        const formToSend = plainToClass(EventsExcelFormModel, this.form, { excludeExtraneousValues: true });
        const hotelId = +this.$route.params.hotelId;

        if (this.isDownloadDisabled) {
            return;
        }

        this.isLoading = true;
        this.eventsManagerService.getExcel(formToSend, hotelId)
            .then(() => this.closeForm())
            .finally(() => { this.isLoading = false; });
    }
}

