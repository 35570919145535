/* eslint-disable camelcase */
import { Expose, Transform } from 'class-transformer';
import { ArrayMinSize, IsNotEmpty } from 'class-validator';

export default class EventsExcelFormModel {
    @ArrayMinSize(1)
    @Expose()
    type!: string[];

    @ArrayMinSize(1)
    @Expose()
    @Transform(value => value || [])
    entity_type!: string[];

    @Expose()
    country_codes!: string[];

    market_ids: number[] = [];

    @IsNotEmpty()
    @Expose()
    @Transform((_, plain) => plain.monthrange[0])
    start_date!: string;

    @IsNotEmpty()
    @Expose()
    @Transform((_, plain) => plain.monthrange[1])
    end_date!: string;
}
